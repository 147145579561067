import React from "react";
import CustomSection, {
  customSectionBodyPadding,
} from "@components/Custom/CustomSection";
import {
  CLAIM_SECTION_RESULTS_MAPPER,
  CLAIM_SECTION_VISIT_DETAILS,
  CLAIM_SECTION_VISIT_DETAILS_TITLE,
} from "@constants/claims/claims";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ClaimEvaluationResults from "../Claim/ClaimEvaluationResults";
import { useClaimContext } from "../Claim/context";
import ClaimVisitData from "./ClaimVisitData";
import ClaimVisitFormData from "./ClaimVisitFormData";

const section = CLAIM_SECTION_VISIT_DETAILS;
const title = CLAIM_SECTION_VISIT_DETAILS_TITLE;

const getFormData = (claim) => {
  const {
    claim_form_number,
    visit_type,
    encounter_type,
    encounter_type_description,
    visit_date,
    discharge_date,
    admission_type,
  } = claim;
  return {
    claim_form_number,
    visit_type,
    encounter_type,
    encounter_type_description,
    visit_date,
    discharge_date,
    admission_type,
  };
};

export default function ClaimVisit() {
  const {
    isCreateOrUpdateMode = false,
    claim,
    setFormData: setClaimFormData = () => {},
  } = useClaimContext();
  const { error_fieldnames = [] } = claim || {};
  const results = claim?.[CLAIM_SECTION_RESULTS_MAPPER[section]] || [];
  const hasResults = results.length > 0;
  const formData = getFormData(claim);
  const setFormData = (formData) =>
    setClaimFormData((prev) => ({ ...prev, ...formData }));

  return (
    <CustomSection
      title={title}
      isCollapsable={!isCreateOrUpdateMode}
      bodyProps={{ p: 0 }}
    >
      <Box p={customSectionBodyPadding}>
        {isCreateOrUpdateMode ? (
          <ClaimVisitFormData
            formData={formData}
            setFormData={setFormData}
            error_fieldnames={error_fieldnames}
          />
        ) : (
          <ClaimVisitData
            object={claim}
            useCustomSection={false}
            useErrorFieldnames={true}
          />
        )}
      </Box>
      {hasResults && (
        <>
          <Divider />
          <Box p={customSectionBodyPadding}>
            <ClaimEvaluationResults
              results={results}
              isCreateOrUpdateMode={isCreateOrUpdateMode}
              type={section}
              useListItemCollapse={true}
            />
          </Box>
        </>
      )}
    </CustomSection>
  );
}
