import { getRowValue } from "@helpers/parse/csv";
import { v4 as uuidv4 } from "uuid";

export const emptyMedicationFormData = {
  code: "",
  trade_name: null,
  generic_name: null,
  dosage_form: null,
  package_type: null,
  ndc_tag: null,
  medication_active_ingredients: [],
};

export const emptyMedicationActiveIngredientFormData = {
  generic_name: null,
  concentration: "",
  concentration_unit: null,
  package_size: "",
  package_size_unit: null,
};

export const formatMedicationFormData = ({ isCheck, formData }) => {
  const {
    id = undefined,
    code,
    trade_name,
    generic_name,
    dosage_form,
    package_type,
    ndc_tag,
    medication_active_ingredients = [],
  } = formData;

  return {
    id,
    is_check: isCheck,
    code: code?.trim(),
    trade_name,
    generic_name,
    dosage_form,
    package_type,
    ndc_tag,
    medication_active_ingredients: medication_active_ingredients.map(
      ({
        generic_name,
        concentration,
        concentration_unit,
        package_size,
        package_size_unit,
      }) => ({
        generic_name,
        concentration: isNaN(parseFloat(concentration)) ? null : concentration,
        concentration_unit,
        package_size: isNaN(parseFloat(package_size)) ? null : package_size,
        package_size_unit,
      })
    ),
  };
};

const medicationRowToObject = (row) => {
  const {
    code,
    ndc_tag,
    trade_name,
    generic_name,
    dosage_form,
    package_type,
    medication_active_ingredient_generic_name,
    medication_active_ingredient_concentration,
    medication_active_ingredient_concentration_unit,
    medication_active_ingredient_package_size,
    medication_active_ingredient_package_size_unit,
  } = getRowValue({
    row,
    columns: [
      { headerName: "CODE", fieldname: "code" },
      { headerName: "NDC TAG", fieldname: "ndc_tag" },
      { headerName: "TRADE NAME", fieldname: "trade_name" },
      { headerName: "GENERIC NAME", fieldname: "generic_name" },
      { headerName: "DOSAGE FORM", fieldname: "dosage_form" },
      { headerName: "PACKAGE TYPE", fieldname: "package_type" },
      {
        headerName: "ACTIVE INGREDIENT GENERIC NAME",
        fieldname: "medication_active_ingredient_generic_name",
      },
      {
        headerName: "ACTIVE INGREDIENT CONCENTRATION",
        fieldname: "medication_active_ingredient_concentration",
      },
      {
        headerName: "ACTIVE INGREDIENT CONCENTRATION UNIT",
        fieldname: "medication_active_ingredient_concentration_unit",
      },
      {
        headerName: "ACTIVE INGREDIENT PACKAGE SIZE",
        fieldname: "medication_active_ingredient_package_size",
      },
      {
        headerName: "ACTIVE INGREDIENT PACKAGE SIZE UNIT",
        fieldname: "medication_active_ingredient_package_size_unit",
      },
    ],
  });

  let medication_active_ingredient = null;

  if (
    Boolean(medication_active_ingredient_generic_name) ||
    Boolean(medication_active_ingredient_concentration) ||
    Boolean(medication_active_ingredient_concentration_unit) ||
    Boolean(medication_active_ingredient_package_size) ||
    Boolean(medication_active_ingredient_package_size_unit)
  ) {
    medication_active_ingredient = {
      generic_name: {
        name: medication_active_ingredient_generic_name,
      },
      concentration: medication_active_ingredient_concentration,
      concentration_unit: {
        name: medication_active_ingredient_concentration_unit,
      },
      package_size: medication_active_ingredient_package_size,
      package_size_unit: {
        name: medication_active_ingredient_package_size_unit,
      },
    };
  }

  const isRowEmpty = !(
    code ||
    trade_name ||
    generic_name ||
    dosage_form ||
    package_type ||
    medication_active_ingredient
  );

  const packageType = Boolean(package_type) ? { name: package_type } : null;
  const dosageForm = Boolean(dosage_form) ? { name: dosage_form } : null;
  const tradeName = Boolean(trade_name) ? { name: trade_name } : null;
  const genericName = Boolean(generic_name) ? { name: generic_name } : null;
  const ndcTag = Boolean(ndc_tag) ? { code: ndc_tag } : null;

  return {
    id: isRowEmpty ? null : uuidv4(),
    code,
    ndc_tag: ndcTag,
    trade_name: tradeName,
    generic_name: genericName,
    dosage_form: dosageForm,
    package_type: packageType,
    medication_active_ingredient,
  };
};

export const medicationRowsToObjects = (rows) => {
  const finalDict = {};
  rows
    .map((row) => medicationRowToObject(row))
    .filter(({ id }) => Boolean(id))
    .forEach(
      ({
        id,
        code,
        ndc_tag,
        trade_name,
        generic_name,
        dosage_form,
        package_type,
        medication_active_ingredient,
      }) => {
        const prevInstance = {
          ...(finalDict[code] || emptyMedicationFormData),
        };
        const newInstance = {
          id,
          code: code || prevInstance.code,
          ndc_tag: ndc_tag || prevInstance.ndc_tag,
          trade_name: trade_name || prevInstance.trade_name,
          generic_name: generic_name || prevInstance.generic_name,
          dosage_form: dosage_form || prevInstance.dosage_form,
          package_type: package_type || prevInstance.package_type,
        };
        const object = { ...prevInstance, ...newInstance };
        if (Boolean(medication_active_ingredient)) {
          finalDict[code] = {
            ...object,
            medication_active_ingredients: [
              ...object.medication_active_ingredients,
              medication_active_ingredient,
            ],
          };
        } else {
          finalDict[code] = object;
        }
      }
    );

  return Object.values(finalDict);
};

export const getUnitStr = (unit) => unit?.name || "";

export const getDrugNameStr = (drug_name) => drug_name?.name || "";
