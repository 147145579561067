import React from "react";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import Stack from "@mui/material/Stack";
import MedicationActiveIngredientsData from "../MedicationData/MedicationActiveIngredientsData";
import MedicationDetailsData from "../MedicationData/MedicationDetailsData";

export default function MedicationData({ object = {}, isMinified = false }) {
  const { isStaff } = useStaffContextProvider();
  const spacing = isMinified ? 2 : 4;
  const { medication_active_ingredients = [], ...otherData } = object || {};

  return (
    <Stack spacing={spacing}>
      <MedicationDetailsData
        object={otherData}
        isStaff={isStaff}
        isMinified={isMinified}
      />
      <MedicationActiveIngredientsData
        medication_active_ingredients={medication_active_ingredients}
        isMinified={isMinified}
      />
    </Stack>
  );
}

export const MedicationDataReviewAndConfirm = ({ object }) => (
  <MedicationData object={object} isMinified={true} />
);
