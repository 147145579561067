import React from "react";
import { getClaimProcedureSectionId } from "@constants/claims/claim-evaluation-results";
import Typography from "@mui/material/Typography";
import ClaimEvaluationResultLink from "../ClaimEvaluationResultLink";
import CustomTooltip from "@components/Custom/CustomTooltip";

const ICD_CODE_KEY = "{icd_code}";
const ICD_CODES_KEY = "{icd_codes}";
const INTERNAL_CODES_KEY = "{internal_codes}";
const ERROR_MESSAGE_KEY = "{error_message}";

export default function ClaimIcdEvaluationResultContext({
  icd = {},
  claim_procedures = [],
  icd_code = "",
  icd_codes = [],
  description = "",
  error_message = "",
  isCreateOrUpdateMode = false,
}) {
  const { code, description: icdDescription } = icd || {};

  return {
    [ERROR_MESSAGE_KEY]: (
      <Typography variant="body2" display="inline">
        {error_message}
      </Typography>
    ),
    [ICD_CODES_KEY]: icd_codes?.map(
      ({ code: otherCode, description: otherDescription }, index) => (
        <CustomTooltip key={index} title={otherDescription}>
          <Typography variant="body2" display="inline" fontWeight="bold">
            {index !== 0 && ", "} {otherCode}
          </Typography>
        </CustomTooltip>
      )
    ),
    [ICD_CODE_KEY]: (
      <Typography
        variant="body2"
        display="inline"
        fontWeight="bold"
        title={description || icdDescription}
      >
        {icd_code || code}
      </Typography>
    ),
    [INTERNAL_CODES_KEY]: (
      <span>
        {claim_procedures?.map((claim_procedure, index) => {
          const { id, organization_bundle } = claim_procedure || {};
          const { internal_code, description } = organization_bundle || {};
          const href = getClaimProcedureSectionId({
            isCreateOrUpdateMode,
            id,
          });
          const delimiter = index < claim_procedures.length - 1 ? ", " : "";
          return (
            <React.Fragment key={id}>
              <ClaimEvaluationResultLink
                to={href}
                value={internal_code}
                title={description}
              />
              {delimiter}
            </React.Fragment>
          );
        })}
      </span>
    ),
  };
}
