import React from "react";
import {
  CLAIM_SECTION_RESULTS_MAPPER,
  CLAIM_SECTION_WIDE,
} from "@constants/claims/claims";
import ClaimEvaluationResults from "..";
import { useClaimContext } from "../../context";

const section = CLAIM_SECTION_WIDE;

export default function ClaimWideEvaluationResultsGeneral() {
  const { isCreateOrUpdateMode = false, claim } = useClaimContext();
  const results = claim?.[CLAIM_SECTION_RESULTS_MAPPER[section]] || [];
  const hasResults = results.length > 0;

  if (!hasResults) return null;

  return (
    <ClaimEvaluationResults
      results={results}
      isCreateOrUpdateMode={isCreateOrUpdateMode}
      type={section}
      useListItemCollapse={false}
    />
  );
}
