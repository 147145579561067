import React from "react";
import ClaimStatus from "@components/Authenticated/ClaimSubmissions/Claims/Claim/Claim/ClaimStatus";
import { CustomAmountApproved } from "@components/Custom/CustomAmountApproved";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import { getClaimHref } from "@constants/static/routing";
import { getFullName } from "@helpers/hooks/objects";
import { parseDate, parseTimeSince } from "@helpers/parse/time";

export default function ClaimsGridProps({
  organization__slug,
  urlSearch = "",
}) {
  return {
    columns: [
      {
        flex: 1,
        field: "claim_form_number",
        headerName: "Claim form number",
        renderCell: (params) => (
          <CustomLabeledText
            value={params?.value || "-"}
            helperText={parseDate(params.row.visit_date)}
            wrapperProps={{ height: "inherit" }}
            typographyProps={{ className: "ellipsis-1" }}
            helperTextProps={{ className: "ellipsis-1" }}
          />
        ),
      },
      {
        flex: 0.5,
        field: "version",
        headerName: "Version",
      },
      {
        flex: 1,
        field: "organization_patient__first_name",
        headerName: "Patient",
        valueGetter: (params) => {
          const { first_name = "", last_name = "" } =
            params?.row?.organization_patient || {};
          const full_name = getFullName({ first_name, last_name });
          return full_name || "-";
        },
        renderCell: (params) => (
          <CustomLabeledText
            value={params?.value}
            helperText={params.row.organization_patient?.patient_id}
            wrapperProps={{ height: "inherit" }}
            typographyProps={{ className: "ellipsis-1" }}
            helperTextProps={{ className: "ellipsis-1" }}
          />
        ),
      },
      {
        flex: 1,
        field: "amount_approved",
        headerName: "Approved",
        renderCell: ({ row }) => {
          const { amount_requested, amount_approved, currency } = row;
          return (
            <CustomAmountApproved
              object={{ amount_requested, amount_approved, currency }}
            />
          );
        },
      },
      {
        flex: 1.5,
        field: "status",
        headerName: "Status",
        renderCell: ({ row, value }) => (
          <ClaimStatus
            status={value}
            time_deleted={row.time_deleted}
            time_locked={row.time_locked}
            num_failure_evaluation_results={row.num_failure_evaluation_results}
            num_alert_evaluation_results={row.num_alert_evaluation_results}
            rowSpacing={1}
            useDelay={true}
          />
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last Updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ],
    disableRowSelectionOnClick: true,
    navigateTo: ({ id, claim_submission }) =>
      getClaimHref({
        organization__slug,
        claim_submission__id: claim_submission?.id || claim_submission,
        claim__id: id,
        urlSearch,
      }),
  };
}
