import React from "react";
import CustomAvatar from "@components/Custom/CustomAvatar";
import { getFullName } from "@helpers/hooks/objects";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const isUserOptionEqualToValue = (option, value) =>
  option?.id === value?.id;

export const getUserOptionLabel = (option) => {
  const { first_name, last_name } = option;
  return getFullName({ first_name, last_name });
};

export const renderUserOption = (props, option) => {
  const { first_name, last_name, email, image } = option;
  const full_name = getFullName({ first_name, last_name });
  return (
    <div {...props}>
      <Stack spacing={1.5} direction="row" alignItems="center">
        <CustomAvatar src={image} className="sizeSm" />
        <div style={{ display: "grid" }}>
          <Typography variant="body2">{full_name}</Typography>
          <Typography variant="caption" color="textSecondary">
            {email}
          </Typography>
        </div>
      </Stack>
    </div>
  );
};
