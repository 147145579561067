import ClaimIcdEvaluationResultContext from "./ClaimIcdEvaluationResultContext";

export default function ClaimDiagnosisEvaluationResultContext({
  result = {},
  isCreateOrUpdateMode = false,
}) {
  const {
    claim_diagnosis,
    claim_procedures,
    icd_code,
    icd_codes,
    description,
    error_message,
  } = result || {};
  const { icd } = claim_diagnosis || {};

  return ClaimIcdEvaluationResultContext({
    icd,
    claim_procedures,
    icd_code,
    icd_codes,
    description,
    error_message,
    isCreateOrUpdateMode,
  });
}
