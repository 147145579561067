import React from "react";
import CustomLabeledCheckbox from "@components/Custom/CustomLabeledCheckbox";

export const CLAIM_EXPORT_OTHER_FORMDATA = {
  use_report: false,
  use_inputted_icd_code: false,
};

const CLAIM_EXPORT_FIELDS = [
  {
    name: "use_report",
    label: "Include a report summary for each claim",
  },
  {
    name: "use_inputted_icd_code",
    label: "Use orginal ICD codes",
  },
];

export default function ClaimExportOtherFormData({
  formData = {},
  setFormData,
}) {
  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.checked }));

  return (
    <div>
      {CLAIM_EXPORT_FIELDS.map(({ name, label }, index) => (
        <CustomLabeledCheckbox
          key={index}
          name={name}
          checked={formData?.[name] || false}
          label={label}
          onChange={handleChange}
        />
      ))}
    </div>
  );
}
