import React from "react";
import CustomActionButtons from "@components/Custom/CustomActionButtons";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomTooltip from "@components/Custom/CustomTooltip";
import {
  CLAIM_ICD_CODE_ERROR_FIELDNAMES_DESCRIPTION,
  CLAIM_ICD_CODE_ERROR_FIELDNAMES_ICD,
  isErrorFieldnames,
} from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_SECTION_ICD_TITLE_INVERSE_MAPPER,
  CLAIM_SECTION_SYMPTOMS,
  getIcdTtitle,
} from "@constants/claims/claims";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { DragIndicatorIcon } from "@rimads/assets";
import { AVEY_GREY_DARK } from "@styles/theme";

export default function ClaimIcdData({
  index,
  type = CLAIM_SECTION_SYMPTOMS,
  object = {},
  useErrorFieldnames = false,
  isCreateOrUpdateMode = false,
  handleSelectClaimIcd = () => {},
  handleRemoveClaimIcd = () => {},
  handleMigrateClaimIcd = () => {},
  useMigrateIcds = true,
}) {
  const { capitalized } = getIcdTtitle({ index, type });
  const {
    id,
    inputted_icd_code,
    icd = {},
    description,
    note,
    error_fieldnames = [],
  } = object || {};
  const { code } = icd || {};
  const {
    [CLAIM_ICD_CODE_ERROR_FIELDNAMES_ICD]: icd_error,
    [CLAIM_ICD_CODE_ERROR_FIELDNAMES_DESCRIPTION]: description_error,
  } = isErrorFieldnames(useErrorFieldnames ? error_fieldnames || [] : []);

  const helperText =
    code !== inputted_icd_code && inputted_icd_code
      ? `Originally: ${inputted_icd_code}`
      : "";

  const handleSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSelectClaimIcd({ object, index });
  };
  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemoveClaimIcd({ index });
  };
  const handleMigrate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleMigrateClaimIcd({ index });
  };

  return (
    <Stack
      key={id}
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      {isCreateOrUpdateMode && (
        <CustomTooltip
          title="Drag to reorder"
          tooltipProps={{ placement: "left", className: "" }}
        >
          <DragIndicatorIcon dimension={12} primary_fill={AVEY_GREY_DARK} />
        </CustomTooltip>
      )}
      <div style={{ flexGrow: 1 }}>
        <CustomGrid>
          <CustomLabeledText
            label={capitalized}
            value={code || inputted_icd_code}
            helperText={helperText}
            isErrorField={icd_error}
            wrapperProps={{ minWidth: 100 }}
          />
          <CustomLabeledText
            label="Description"
            value={description}
            isErrorField={description_error}
            wrapperProps={{ minWidth: 200 }}
          />
          <CustomLabeledText label="Note" value={note} />
        </CustomGrid>
      </div>
      {isCreateOrUpdateMode && (
        <CustomActionButtons
          handleEdit={handleSelect}
          handleRemove={handleRemove}
          direction="row-reverse"
        >
          {useMigrateIcds && (
            <MenuItem onClick={handleMigrate}>
              <span>
                {"Move to "}
                <b>{CLAIM_SECTION_ICD_TITLE_INVERSE_MAPPER[type]}</b>
              </span>
            </MenuItem>
          )}
        </CustomActionButtons>
      )}
    </Stack>
  );
}
