export const getOptions = (mapper = {}) =>
  Object.entries(mapper).map(([k, v]) => ({
    key: k,
    value: v,
  }));

export function getNumActiveFilters(filters) {
  return Object.values(filters)
    .map((category) => category.filter(Boolean).length)
    .reduce((a, b) => a + b, 0);
}

export const getFullName = ({ first_name, last_name }) =>
  `${first_name || ""} ${last_name || ""}`.trim();
