import React from "react";
import AppConfigurationsTitleComponent from "@components/Authenticated/Configurations/Configurations/AppConfigurations/AppConfigurationsTitleComponent";
import { useStaffContextProvider } from "@components/ContextProviders/staff-context-provider";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import {
  CustomAlertDialog,
  initialAlertState,
} from "@components/Custom/CustomActionDialog/CustomAlertDialog";
import {
  CustomSummaryDialog,
  initialSummaryState,
} from "@components/Custom/CustomActionDialog/CustomSummaryDialog";
import {
  emptyUnitFormData,
  formatUnitFormData,
  UNIT_TYPE_MAPPER,
} from "@constants/avey/units";
import MenuItem from "@mui/material/MenuItem";
import { createUnit, invalidateUnitsTSQ } from "@redux/actions/avey/units";
import { enqueueSnackbar } from "notistack";
import { UnitDataReviewAndConfirm } from "../Unit/UnitData";
import UnitFormData from "./UnitFormData";

export default function UnitCreateForm({
  withCustomButton = true,
  parentIsOpen = false,
  parentSetIsOpen = undefined,
  handleSelect = undefined,
  isUnitTypeFixed = false,
  initialFormData = undefined,
  unit_type = undefined,
}) {
  const label = UNIT_TYPE_MAPPER[unit_type];
  const lowercasedLabel = label?.toLowerCase();
  const { as_staff, isStaff } = useStaffContextProvider();
  const [isOpen, setIsOpen] = React.useState(parentIsOpen);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState({
    ...emptyUnitFormData,
    ...(initialFormData || {}),
  });
  const [summaryState, setSummaryState] = React.useState(initialSummaryState);
  const [alertState, setAlertState] = React.useState(initialAlertState);
  const { isOpen: isOpenSummary, summaries } = summaryState;
  const { isOpen: isOpenAlert } = alertState;
  const isOpenSummaryDialog = isOpenSummary && !isOpenAlert;
  const isOpenMainDialog = isOpen && !isOpenSummaryDialog && !isOpenAlert;
  const TitleComponent = AppConfigurationsTitleComponent({ isStaff });

  const handleIsOpen = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    Boolean(parentSetIsOpen) ? parentSetIsOpen(!isOpen) : setIsOpen(!isOpen);
  };

  const handleSubmit = (e, isCheck = true) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProcessing(true);
    const formattedFormData = formatUnitFormData({ isCheck, formData });
    createUnit({ data: formattedFormData, as_staff })
      .then(({ payload }) => {
        const { instance, summaries, exceptions } = payload;
        if (Boolean(instance)) {
          enqueueSnackbar({
            variant: "success",
            message: `${label} successfully created`,
          });
          invalidateUnitsTSQ();
          Boolean(parentSetIsOpen) ? parentSetIsOpen(false) : setIsOpen(false);
          Boolean(handleSelect) && handleSelect(instance);
        } else if (exceptions?.length > 0) {
          setAlertState({ isOpen: true, errors: exceptions });
        } else {
          setSummaryState({ isOpen: true, summaries });
        }
      })
      .catch(({ payload }) => {
        setAlertState({ isOpen: true, errorStr: payload });
        setSummaryState((prev) => ({ ...prev, isOpen: false }));
      })
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitData = (e) => handleSubmit(e, false);
  const handleCheckData = (e) => handleSubmit(e, true);

  React.useEffect(() => setIsOpen(parentIsOpen), [parentIsOpen]);

  React.useEffect(() => {
    isOpen && setFormData({ ...emptyUnitFormData, ...(initialFormData || {}) });
    setAlertState(initialAlertState);
    setSummaryState(initialSummaryState);
  }, [isOpen, initialFormData]);

  return (
    <>
      {withCustomButton && (
        <MenuItem onClick={handleIsOpen}>{`Add a ${lowercasedLabel}`}</MenuItem>
      )}
      <CustomActionDialog
        isOpen={isOpenMainDialog}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleCheckData}
        TitleComponent={TitleComponent}
        titleComponentProps={{ title: `New ${lowercasedLabel}` }}
      >
        <UnitFormData
          formData={formData}
          setFormData={setFormData}
          isUnitTypeFixed={isUnitTypeFixed}
        />
      </CustomActionDialog>
      <CustomAlertDialog state={alertState} setAlertState={setAlertState} />
      <CustomSummaryDialog
        summaries={summaries}
        isOpen={isOpenSummaryDialog}
        isProcessing={isProcessing}
        setSummaryState={setSummaryState}
        handleSubmit={handleSubmitData}
        ContentComponent={UnitDataReviewAndConfirm}
        contentComponentProps={{ object: formData }}
        TitleComponent={TitleComponent}
      />
    </>
  );
}
