import React from "react";
import AccountActionDialogTitle from "@components/Authenticated/Account/Account/AccountActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { getFullName } from "@helpers/hooks/objects";
import {
  invalidateUsersTSQ,
  resendInvitation,
} from "@redux/actions/users/users";
import { enqueueSnackbar } from "notistack";

export default function UserManagementReinviteUser({
  isOpen,
  object,
  handleClose = () => {},
}) {
  const { id, first_name, last_name, email } = object;
  const full_name = getFullName({ first_name, last_name });
  const [isProcessing, setIsProcessing] = React.useState(false);
  const title = `Reactivate ${full_name}’s account?`;
  const subtitle = (
    <>
      {"An email with a link will be sent to "}
      <b>{email}</b>
    </>
  );

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    resendInvitation(id)
      .then(({ payload }) => {
        setIsProcessing(false);
        handleClose();
        enqueueSnackbar({
          message: "Invitation link successfully sent",
          variant: "success",
        });
        invalidateUsersTSQ();
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        enqueueSnackbar({ message: payload, variant: "error" });
      });
  };

  return (
    <CustomActionDialog
      isOpen={isOpen}
      isProcessing={isProcessing}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      TitleComponent={AccountActionDialogTitle}
      titleComponentProps={{ title, user: object }}
      subtitleComponentProps={{ title: subtitle }}
      isBodyEmpty={true}
      noText="No, don’t reactivate"
      yesText="Yes, reactivate"
      yesButtonProps={{ color: "success", variant: "contained" }}
    />
  );
}
