import React from "react";
import AccountActionDialogTitle from "@components/Authenticated/Account/Account/AccountActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import { getFullName } from "@helpers/hooks/objects";
import { deactivateUser, invalidateUsersTSQ } from "@redux/actions/users/users";
import { enqueueSnackbar } from "notistack";
import pluralize from "pluralize";
import UserManagementReassignClaimSubmissionsForm from "../UserManagementReassignClaimSubmissionsForm";

const getSubtitle = ({ full_name = "", numReassignments = 0 }) => {
  let subtitle = `${full_name} has ${pluralize(
    "submission",
    numReassignments,
    true
  )} in progress. `;

  if (numReassignments === 1) {
    subtitle =
      subtitle +
      "It will be reassigned automatically, or you can change it manually.";
  } else if (numReassignments > 1) {
    subtitle =
      subtitle +
      "They will be reassigned automatically, or you can change them manually.";
  }

  return subtitle;
};

export default function UserManagementDeactivateAccount({
  isOpen,
  object,
  handleClose = () => {},
}) {
  const { id, first_name, last_name } = object;
  const full_name = getFullName({ first_name, last_name });
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isCheck, setIsCheck] = React.useState(true);
  const [reassignments, setReassignments] = React.useState([]);
  const subtitle = getSubtitle({
    full_name,
    numReassignments: reassignments?.length,
  });
  const title = isCheck
    ? `Deactivate ${full_name}’s account?`
    : `Transfer ${full_name}’s submissions`;

  React.useEffect(() => {
    isOpen && setIsCheck(true);
  }, [isOpen]);

  const handleSubmit = (e, recursive = false) => {
    e?.preventDefault();
    setIsProcessing(true);
    const isFinalCheck = recursive ? false : isCheck;
    deactivateUser({
      id,
      is_check: isFinalCheck,
      reassignments: isFinalCheck ? [] : reassignments,
    })
      .then(({ payload }) => {
        if (isFinalCheck && payload.length === 0) {
          handleSubmit(e, true);
        } else {
          setIsProcessing(false);
          if (isFinalCheck) {
            setReassignments(payload || []);
            setIsCheck(false);
          } else {
            setIsCheck(true);
            handleClose();
            enqueueSnackbar({
              message: "Account successfully deactivated",
              variant: "success",
            });
            invalidateUsersTSQ();
          }
        }
      })
      .catch(({ payload }) => {
        setIsProcessing(false);
        setIsCheck(true);
        enqueueSnackbar({ message: payload, variant: "error" });
      });
  };

  if (isCheck)
    return (
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        TitleComponent={AccountActionDialogTitle}
        titleComponentProps={{ title, user: object }}
        isBodyEmpty={true}
        noText="No, keep it"
        yesText="Yes, deactivate"
        yesButtonProps={{ color: "secondary", variant: "contained" }}
      />
    );

  return (
    <CustomActionDialog
      isOpen={isOpen}
      isProcessing={isProcessing}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      TitleComponent={AccountActionDialogTitle}
      titleComponentProps={{ title, user: object }}
      subtitleComponentProps={{ title: subtitle }}
      yesText="Deactivate account"
    >
      <UserManagementReassignClaimSubmissionsForm
        reassignments={reassignments}
        setReassignments={setReassignments}
        user={object}
      />
    </CustomActionDialog>
  );
}
