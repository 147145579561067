import React from "react";
import OrganizationPatientData from "@components/Authenticated/Configurations/OrganizationPatients/OrganizationPatient/OrganizationPatientData";
import CustomNotProvidedText from "@components/Custom/CustomNotProvidedText";
import CustomSection from "@components/Custom/CustomSection";
import {
  CLAIM_SECTION_ORGANIZATION_PATIENT,
  CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE,
  CLAIM_SECTION_RESULTS_MAPPER,
} from "@constants/claims/claims";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ClaimEvaluationResults from "../Claim/ClaimEvaluationResults";
import { useClaimContext } from "../Claim/context";
import ClaimOrganizationPatientFormData from "./ClaimOrganizationPatientFormData";

const section = CLAIM_SECTION_ORGANIZATION_PATIENT;
const title = CLAIM_SECTION_ORGANIZATION_PATIENT_TITLE;

export default function ClaimOrganizationPatient() {
  const {
    claim = {},
    isCreateOrUpdateMode = false,
    setFormData = () => {},
  } = useClaimContext();
  const { organization_patient = {} } = claim;
  const handleChangeOrganizationPatient = React.useCallback(
    (newValue) => {
      setFormData((prev) => ({ ...prev, organization_patient: newValue }));
    },
    [setFormData]
  );
  const hasOrganizationPatient = Boolean(organization_patient);
  const results = claim?.[CLAIM_SECTION_RESULTS_MAPPER[section]] || [];
  const hasResults = results.length > 0;

  return (
    <CustomSection title={title} isCollapsable={!isCreateOrUpdateMode}>
      <Stack spacing={3}>
        {isCreateOrUpdateMode ? (
          <ClaimOrganizationPatientFormData
            formData={organization_patient}
            handleChangeOrganizationPatient={handleChangeOrganizationPatient}
          />
        ) : hasOrganizationPatient ? (
          <OrganizationPatientData
            object={organization_patient}
            useCustomSection={false}
          />
        ) : (
          <CustomNotProvidedText title={title} />
        )}
        {hasResults && (
          <>
            <Divider />
            <ClaimEvaluationResults
              results={results}
              isCreateOrUpdateMode={isCreateOrUpdateMode}
              type={section}
              useListItemCollapse={true}
            />
          </>
        )}
      </Stack>
    </CustomSection>
  );
}
