import React from "react";
import CustomActionButtons from "@components/Custom/CustomActionButtons";
import { customSectionBodyPadding } from "@components/Custom/CustomSection";
import { getClaimProcedureSectionId } from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_SECTION_PROCEDURES,
  CLAIM_SECTION_RESULTS_MAPPER,
} from "@constants/claims/claims";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import ClaimEvaluationResults from "../Claim/ClaimEvaluationResults";
import ClaimProcedureData from "./ClaimProcedureData";

const section = CLAIM_SECTION_PROCEDURES;

export default function ClaimProcedure({
  index,
  object,
  isCreateOrUpdateMode,
  handleSelectClaimProcedure = () => {},
  handleRemoveClaimProcedure = () => {},
  handleMigrateClaimProcedure = () => {},
}) {
  const { id, organization_bundle } = object;
  const results = object?.[CLAIM_SECTION_RESULTS_MAPPER[section]] || [];
  const sectionId = getClaimProcedureSectionId({ isCreateOrUpdateMode, id });
  const hasNoOrganizationBundle = !Boolean(organization_bundle);
  const hasResults = results.length > 0;

  const handleSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSelectClaimProcedure({ object, index });
  };
  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemoveClaimProcedure({ index });
  };
  const handleMigrate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleMigrateClaimProcedure({ index });
  };

  return (
    <Box
      key={sectionId}
      id={sectionId}
      style={{ scrollMarginTop: 166 }}
      p={customSectionBodyPadding}
    >
      <Stack spacing={3}>
        <Stack direction="row" spacing={3} justifyContent="space-between">
          <ClaimProcedureData object={object} useErrorFieldnames={true} />
          {isCreateOrUpdateMode && (
            <CustomActionButtons
              handleEdit={handleSelect}
              handleRemove={handleRemove}
              direction="column"
            >
              {hasNoOrganizationBundle && (
                <MenuItem onClick={handleMigrate}>
                  <span>
                    {"Move to "}
                    <b>{" Medications"}</b>
                  </span>
                </MenuItem>
              )}
            </CustomActionButtons>
          )}
        </Stack>
        {hasResults && (
          <>
            <Divider />
            <ClaimEvaluationResults
              results={results}
              isCreateOrUpdateMode={isCreateOrUpdateMode}
              type={section}
              useListItemCollapse={true}
            />
          </>
        )}
      </Stack>
    </Box>
  );
}
