import { getOptions } from "@helpers/hooks/objects";
import { getRowValue } from "@helpers/parse/csv";
import { v4 as uuidv4 } from "uuid";

export const UNIT_TYPE_CONCENTRATION = "C";
export const UNIT_TYPE_DOSAGE_FORM = "D";
export const UNIT_TYPE_PACKAGE_SIZE = "P";
export const UNIT_TYPE_PACKAGE_TYPE = "T";

export const UNIT_TYPE_MAPPER = {
  [UNIT_TYPE_CONCENTRATION]: "Concentration unit",
  [UNIT_TYPE_DOSAGE_FORM]: "Dosage Form",
  [UNIT_TYPE_PACKAGE_SIZE]: "Package Size unit",
  [UNIT_TYPE_PACKAGE_TYPE]: "Package Type",
  [null]: "Unit",
};

export const UNIT_TYPE_OPTIONS = getOptions(UNIT_TYPE_MAPPER);

export const emptyUnitFormData = {
  name: "",
  description: "",
  unit_type: UNIT_TYPE_CONCENTRATION,
};

export const formatUnitFormData = ({ isCheck, formData }) => {
  const { id = undefined, name, description, unit_type } = formData;

  return {
    id,
    is_check: isCheck,
    name: name?.trim(),
    description: description?.trim(),
    unit_type,
  };
};

const unitRowToObject = (row) => {
  const { name, description, unit_type } = getRowValue({
    row,
    columns: [
      { headerName: "NAME", fieldname: "name" },
      { headerName: "DESCRIPTION", fieldname: "description" },
      { headerName: "UNIT TYPE", fieldname: "unit_type" },
    ],
  });

  const isRowEmpty = !(name || description || unit_type);
  return {
    id: isRowEmpty ? null : uuidv4(),
    name,
    description,
    unit_type,
  };
};

export const unitRowsToObjects = (rows) =>
  rows.map((row) => unitRowToObject(row)).filter(({ id }) => Boolean(id));
