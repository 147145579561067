import React from "react";
import CustomListAvatarItem from "@components/Custom/CustomListAvatarItem";
import {
  USER_MANAGEMENT_ACTION_DEACTIVATE_ACCOUNT,
  USER_MANAGEMENT_ACTION_EDIT_PROFILE,
  USER_MANAGEMENT_ACTION_REINVITE_USER,
  USER_ORGANIZATION_ROLE_MAPPER,
  USER_STATUS_ACTIVATED,
  USER_STATUS_DEACTIVATED,
  USER_STATUS_PENDING,
} from "@constants/users/users";
import { getFullName } from "@helpers/hooks/objects";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import UserStatus, { getStatus } from "../UserManagementUserStatus";

const getActions = ({ row, userId, setState = () => {} }) => {
  const { id, is_active, activation_date } = row;

  if (id === userId) return [];

  const status = getStatus({ is_active, activation_date });
  const handleEditProfile = () =>
    setState({
      object: row,
      action: USER_MANAGEMENT_ACTION_EDIT_PROFILE,
      isOpen: true,
    });
  const handleDeactivateAccount = () =>
    setState({
      object: row,
      action: USER_MANAGEMENT_ACTION_DEACTIVATE_ACCOUNT,
      isOpen: true,
    });
  const handleReinviteUser = () =>
    setState({
      object: row,
      action: USER_MANAGEMENT_ACTION_REINVITE_USER,
      isOpen: true,
    });

  switch (status) {
    case USER_STATUS_ACTIVATED:
      return [
        <GridActionsCellItem
          showInMenu
          label="Edit profile"
          onClick={handleEditProfile}
        />,
        <GridActionsCellItem
          showInMenu
          label="Deactivate account"
          onClick={handleDeactivateAccount}
        />,
      ];

    case USER_STATUS_DEACTIVATED:
      return [
        <GridActionsCellItem
          showInMenu
          label="Re-invite user"
          onClick={handleReinviteUser}
        />,
      ];

    case USER_STATUS_PENDING:
      return [
        <GridActionsCellItem
          showInMenu
          label="Edit profile"
          onClick={handleEditProfile}
        />,
        <GridActionsCellItem
          showInMenu
          label="Re-send activation link"
          onClick={handleReinviteUser}
        />,
        <GridActionsCellItem
          showInMenu
          label="Revoke invitation"
          onClick={handleDeactivateAccount}
        />,
      ];

    default:
      return [];
  }
};

export default function UserManagementGridProps({
  userId,
  setState = () => {},
}) {
  return {
    columns: [
      {
        flex: 1,
        field: "first_name",
        headerName: "Name",
        renderCell: ({ row }) => {
          const { first_name, last_name, image } = row || {};
          const full_name = getFullName({ first_name, last_name });
          return (
            <CustomListAvatarItem
              imageProps={{ src: image, className: "sizeSm" }}
              textProps={{ children: full_name }}
            />
          );
        },
      },
      {
        flex: 1,
        field: "email",
        headerName: "Email",
      },
      // {
      //   flex: 1,
      //   field: "phone_number",
      //   headerName: "Mobile Number",
      //   valueGetter: ({ value }) => value || "-",
      // },
      {
        flex: 1,
        field: "role",
        headerName: "Role",
        valueGetter: ({ value }) => USER_ORGANIZATION_ROLE_MAPPER[value],
      },
      {
        flex: 1,
        field: "is_active",
        headerName: "Status",
        renderCell: ({ row }) => (
          <UserStatus
            is_active={row.is_active}
            activation_date={row.activation_date}
          />
        ),
      },
      {
        flex: 0.5,
        field: "actions",
        type: "actions",
        getActions: ({ row }) => getActions({ row, userId, setState }),
      },
    ],
    disableRowSelectionOnClick: true,
  };
}
