import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import { useQueryFetchClaimHistory } from "@redux/actions/claims/claims";
import { useParams } from "react-router-dom";
import { ClaimHeaderTitle } from ".";
import ClaimBody from "../ClaimBody";
import ClaimStatus from "../ClaimStatus";
import { ClaimContext } from "../context";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const {
    status,
    time_deleted,
    time_locked,
    num_failure_evaluation_results,
    num_alert_evaluation_results,
  } = object;

  return (
    <ClaimStatus
      status={status}
      time_deleted={time_deleted}
      time_locked={time_locked}
      num_failure_evaluation_results={num_failure_evaluation_results}
      num_alert_evaluation_results={num_alert_evaluation_results}
    />
  );
};

const VersionHistoryObjectDataComponent = ({ object = null }) => (
  <ClaimContext.Provider value={{ claim: object }}>
    <ClaimBody useSummary={true} />
  </ClaimContext.Provider>
);

export default function ClaimVersionHistory() {
  const {
    organization__slug,
    claim_submission__id,
    claim__id: id,
  } = useParams();
  const useQueryFnProps = {
    id,
    filters: { organization__slug, claim_submission__id },
  };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchClaimHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={VersionHistoryObjectDataComponent}
      VersionHistoryObjectTitleComponent={ClaimHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
