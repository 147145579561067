import {
  EXPORT_STATUS_FAILURE,
  EXPORT_STATUS_PENDING,
  EXPORT_STATUS_SUCCESS,
} from "@constants/static/export";

export const getExportObjectSnackbarKey = ({ id, status }) =>
  `export-object-${id}-${status}`;

export const parseExportSnackbar = (export_object) => {
  const { id, status } = export_object;
  const key = getExportObjectSnackbarKey({ id, status });
  const context = getSnackbarContext(export_object);
  const variant = "CustomSnackbar";
  const anchorOrigin = { vertical: "bottom", horizontal: "right" };

  return { key, variant, context, anchorOrigin, persist: true };
};

const getSnackbarContext = (object) => {
  const {
    id,
    status,
    name,
    processor_organization,
    submitter_organization,
    exception_str,
    file,
  } = object;
  const image = processor_organization?.image || submitter_organization?.image;
  const key = getExportObjectSnackbarKey({ id, status });
  const context = { key, image };

  switch (status) {
    case EXPORT_STATUS_PENDING:
      return {
        ...context,
        isLoadingVariant: true,
        title: (
          <>
            {"Exporting "}
            <b>{name}</b>
          </>
        ),
      };

    case EXPORT_STATUS_FAILURE:
      return {
        ...context,
        isErrorVariant: true,
        title: (
          <>
            <b>{name}</b> {" could not be exported"}
          </>
        ),
        subtitle: exception_str,
      };

    case EXPORT_STATUS_SUCCESS:
      return {
        ...context,
        isSuccessVariant: true,
        title: (
          <>
            <b>{name}</b>
            {" successfully exported"}
          </>
        ),
        file,
        name,
      };

    default:
      return context;
  }
};
