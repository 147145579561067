import React from "react";
import { DISABLED_ROW_CLASSNAME } from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import { ORGANIZATION_PATIENT_GENDER_MAPPER } from "@constants/organizations/organization-patients";
import { getOrganizationPatientsHref } from "@constants/static/routing";
import { getFullName } from "@helpers/hooks/objects";
import { parseDob, parseTimeSince } from "@helpers/parse/time";
import Typography from "@mui/material/Typography";
import OrganizationPatientIdentitiesDataSummary from "../OrganizationPatient/OrganizationPatientData/OrganizationPatientIdentitiesDataSummary";

export default function OrganizationPatientsGridProps({
  processor_organization__slug,
}) {
  return {
    columns: [
      {
        flex: 1,
        field: "patient_id",
        headerName: "Member ID",
      },
      {
        flex: 1,
        field: "provider_file_number",
        headerName: "Provider File Number",
        valueGetter: ({ value }) => value || "-",
      },
      {
        flex: 1.5,
        field: "first_name",
        headerName: "Name",
        renderCell: ({ row }) => {
          const {
            first_name,
            last_name,
            localized_first_name,
            localized_last_name,
          } = row || {};
          const full_name = getFullName({ first_name, last_name }) || "-";
          const localized_full_name = getFullName({
            first_name: localized_first_name,
            last_name: localized_last_name,
          });
          return (
            <div>
              <Typography variant="body2" className="ellipsis-1">
                {full_name}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className="ellipsis-1"
              >
                {localized_full_name}
              </Typography>
            </div>
          );
        },
      },
      {
        flex: 1,
        field: "gender",
        headerName: "Gender",
        valueGetter: (params) =>
          ORGANIZATION_PATIENT_GENDER_MAPPER[params?.value] || "-",
      },
      {
        flex: 1.5,
        field: "dob",
        headerName: "Date of birth",
        valueGetter: (params) => parseDob(params?.value)?.dobStr || "-",
      },
      {
        flex: 1.5,
        sortable: false,
        field: "organization_patient_identities",
        headerName: "Identities",
        renderCell: ({ value }) => (
          <OrganizationPatientIdentitiesDataSummary
            organization_patient_identities={value}
          />
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ],
    getRowClassName: ({ row }) => row?.time_deleted && DISABLED_ROW_CLASSNAME,
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) =>
      Boolean(processor_organization__slug) &&
      getOrganizationPatientsHref({
        organization__slug: processor_organization__slug,
        organization_patient__id: id,
      }),
  };
}
