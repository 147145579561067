import React from "react";
import CustomSection from "@components/Custom/CustomSection";
import { getClaimIcdsSectionId } from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_SECTION_ICD_FIELDNAME_INVERSE_MAPPER,
  CLAIM_SECTION_ICD_FIELDNAME_MAPPER,
  CLAIM_SECTION_ICD_TEXT_FIELDNAME_MAPPER,
  CLAIM_SECTION_ICD_TITLE_MAPPER,
  CLAIM_SECTION_RESULTS_MAPPER,
  CLAIM_SECTION_SYMPTOMS,
} from "@constants/claims/claims";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ClaimEvaluationResults from "../Claim/ClaimEvaluationResults";
import { useClaimContext } from "../Claim/context";
import ClaimIcdsData from "./ClaimIcdsData";
import ClaimIcdsFormData from "./ClaimIcdsFormData";

export default function ClaimIcds({ type = CLAIM_SECTION_SYMPTOMS }) {
  const {
    isCreateOrUpdateMode = false,
    claim = {},
    setFormData = () => {},
    icd_type,
  } = useClaimContext();
  const fieldname = CLAIM_SECTION_ICD_FIELDNAME_MAPPER[type];
  const inverse_fieldname = CLAIM_SECTION_ICD_FIELDNAME_INVERSE_MAPPER[type];
  const claimIcdsTextFieldname = CLAIM_SECTION_ICD_TEXT_FIELDNAME_MAPPER[type];
  const sectionId = getClaimIcdsSectionId({ isCreateOrUpdateMode, type });
  const title = CLAIM_SECTION_ICD_TITLE_MAPPER[type];
  const claim_icds = claim?.[fieldname] || [];
  const claim_icds_text = claim?.[claimIcdsTextFieldname] || "";
  const results = claim?.[CLAIM_SECTION_RESULTS_MAPPER[type]] || [];
  const numClaimIcds = claim_icds.length;
  const hasClaimIcds = numClaimIcds > 0;
  const hasResults = results.length > 0;
  const subtitle = hasClaimIcds > 0 && numClaimIcds;

  const handleUpdate = (payload) => {
    const newPayload = {};
    const { claim_icd_to_inverse = undefined } = payload;

    if ("claim_icds" in payload) newPayload[fieldname] = payload.claim_icds;
    if ("claim_icds_text" in payload)
      newPayload[claimIcdsTextFieldname] = payload.claim_icds_text;

    setFormData((prev) => {
      let claim_icds_inverse = prev?.[inverse_fieldname] || [];
      if (claim_icd_to_inverse)
        claim_icds_inverse = [...claim_icds_inverse, claim_icd_to_inverse];
      return {
        ...prev,
        ...newPayload,
        [inverse_fieldname]: claim_icds_inverse,
      };
    });
  };

  const formData = { claim_icds, claim_icds_text };

  return (
    <CustomSection
      title={title}
      subtitle={subtitle}
      isCollapsable={!isCreateOrUpdateMode}
      bodyProps={{ id: sectionId, style: { scrollMarginTop: 166 } }}
    >
      <Stack spacing={3}>
        {isCreateOrUpdateMode ? (
          <ClaimIcdsFormData
            formData={formData}
            handleUpdate={handleUpdate}
            type={type}
            icd_type={icd_type}
            useMigrateIcds={true}
          />
        ) : (
          <ClaimIcdsData object={formData} type={type} />
        )}
        {hasResults && (
          <>
            <Divider />
            <ClaimEvaluationResults
              results={results}
              isCreateOrUpdateMode={isCreateOrUpdateMode}
              type={type}
              useListItemCollapse={true}
            />
          </>
        )}
      </Stack>
    </CustomSection>
  );
}
