import React from "react";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomAppliedFilters from "@components/Custom/CustomAppliedFilters";
import {
  ADJUST_FILTERS_TEXT,
  FILTER_BUTTON_ID,
} from "@components/Custom/CustomFilters";
import { CustomOutlinedTextField } from "@components/Custom/CustomOutlinedField";
import { handleElementOnClickDispatchEvent } from "@helpers/hooks/useElementOnClick";
import { getIntOrFloat } from "@helpers/parse/numbers";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { enqueueSnackbar } from "notistack";
import pluralize from "pluralize";
import { parseExportSnackbar } from "./ConfigurationsBulkExportSnackbar";

const initialFormData = { name: "" };

export const useConfigurationsBulkExportForm = ({
  label = "",
  useQueryFnProps = {},
  parentFormData = undefined,
  submitFn = () => {},
  submitFnFinally = () => {},
  usehandleElementOnClickDispatchEvent = true,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const memoizedInitialFormData = React.useMemo(() => {
    if (Boolean(parentFormData))
      return { ...initialFormData, ...parentFormData };
    return initialFormData;
  }, [parentFormData]);
  const [formData, setFormData] = React.useState(memoizedInitialFormData);
  const { name, ...otherFormData } = formData;

  const handleClose = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(false);
  };

  const handleSubmit = ({ e, isCheck = true }) => {
    e?.preventDefault();
    setIsProcessing(true);
    submitFn({
      ...useQueryFnProps,
      data: { is_check: isCheck, name: name.trim(), ...otherFormData },
    })
      .then(({ payload }) => {
        const { count, instance } = payload || {};
        if (isCheck) {
          setCount(count || 0);
        } else {
          enqueueSnackbar(parseExportSnackbar(instance));
          setIsOpen(false);
          submitFnFinally();
        }
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ variant: "error", message: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  const handleCheckData = (e) => {
    e?.preventDefault();
    setIsOpen(true);
    handleSubmit({ e, isCheck: true });
  };
  const handleSubmitData = (e) => handleSubmit({ e, isCheck: false });

  React.useEffect(() => {
    if (isOpen) setFormData(memoizedInitialFormData);
  }, [isOpen, memoizedInitialFormData]);

  const title = `Export ${label}`;
  const hasNoData = count === 0;
  const countrStr = hasNoData
    ? `no ${pluralize(label)}`
    : `${getIntOrFloat(count)} ${pluralize(label, count, false)}`;

  const summary = !(isProcessing && hasNoData) && (
    <>
      {"You have "}
      <b>{countrStr}</b>
      {" that match your filters"}
    </>
  );

  const handleAdjustFilters = ({ e, handleFn }) => {
    e?.preventDefault();
    usehandleElementOnClickDispatchEvent &&
      handleElementOnClickDispatchEvent({ id: FILTER_BUTTON_ID, detail: true });
    handleFn();
  };

  const dialogProps = hasNoData
    ? {
        handleSubmit: (e) => handleAdjustFilters({ e, handleFn: handleClose }),
        isOk: true,
        okText: ADJUST_FILTERS_TEXT,
      }
    : {
        handleCloseAlt: (e) =>
          handleAdjustFilters({ e, handleFn: handleClose }),
        handleSubmit: handleSubmitData,
        yesText: "Export",
        noText: ADJUST_FILTERS_TEXT,
      };

  return {
    title,
    summary,
    dialogProps,
    isOpen,
    isProcessing,
    hasNoData,
    formData,
    setFormData,
    handleClose,
    handleCheckData,
  };
};

export const ConfigurationsBulkExportFormDialog = ({
  title = "",
  summary = "",
  dialogProps = {},
  isOpen = false,
  isProcessing = false,
  hasNoData = false,
  formData = {},
  TitleComponent = undefined,
  ParentFormDataComponent = undefined,
  useQueryFnProps = {},
  setFormData = () => {},
  handleClose = () => {},
  describeFilters = () => {},
}) => {
  const hasParentFormDataComponent = Boolean(ParentFormDataComponent);
  const { searchQuery = "", filters = {} } = useQueryFnProps || {};
  const { name } = formData;

  const handleChangeName = (e) =>
    setFormData((prev) => ({ ...prev, name: e.target.value }));

  return (
    <CustomActionDialog
      isOpen={isOpen}
      isProcessing={isProcessing}
      handleClose={handleClose}
      TitleComponent={TitleComponent}
      titleComponentProps={{ title }}
      {...dialogProps}
    >
      <CustomAppliedFilters
        describeFilters={describeFilters}
        searchQuery={searchQuery}
        filters={filters}
      />
      <Typography variant="body2">{summary}</Typography>
      {!hasNoData && (
        <Stack spacing={2}>
          <CustomOutlinedTextField
            value={name}
            onChange={handleChangeName}
            required
            placeholder="Enter a name for the export file"
          />
          {hasParentFormDataComponent && (
            <ParentFormDataComponent
              formData={formData}
              setFormData={setFormData}
            />
          )}
        </Stack>
      )}
    </CustomActionDialog>
  );
};

export default function ConfigurationsBulkExportForm({
  label = "",
  useQueryFnProps = {},
  TitleComponent = undefined,
  parentFormData = undefined,
  ParentFormDataComponent = undefined,
  submitFn = () => {},
  describeFilters = () => {},
}) {
  const {
    title,
    summary,
    dialogProps,
    isOpen,
    isProcessing,
    hasNoData,
    formData,
    setFormData,
    handleClose,
    handleCheckData,
  } = useConfigurationsBulkExportForm({
    label,
    useQueryFnProps,
    parentFormData,
    submitFn,
  });

  return (
    <>
      <MenuItem onClick={handleCheckData}>{title}</MenuItem>
      <ConfigurationsBulkExportFormDialog
        title={title}
        summary={summary}
        dialogProps={dialogProps}
        isOpen={isOpen}
        isProcessing={isProcessing}
        hasNoData={hasNoData}
        formData={formData}
        setFormData={setFormData}
        handleClose={handleClose}
        TitleComponent={TitleComponent}
        describeFilters={describeFilters}
        ParentFormDataComponent={ParentFormDataComponent}
        useQueryFnProps={useQueryFnProps}
      />
    </>
  );
}
