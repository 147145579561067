import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const isNdcTagOptionEqualToValue = (option, value) =>
  option?.code === value?.code;

export const getNdcTagOptionLabel = (object) => {
  if (!Boolean(object)) return "";
  const { code, name } = object;
  return `${code} ${name}`;
};

export const renderNdcTagOption = (props, option) => {
  const { code, name } = option;
  return (
    <div {...props}>
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography variant="body2" fontWeight="bold">
          {code}
        </Typography>
        <Typography variant="body2" fontWeight="light">
          {name}
        </Typography>
      </Stack>
    </div>
  );
};
