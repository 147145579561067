import React from "react";
import CustomDividedList from "@components/Custom/CustomDividedList";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomStatusTag from "@components/Custom/CustomStatusTag";
import CustomTooltip from "@components/Custom/CustomTooltip";
import { getUploadsHref } from "@constants/static/routing";
import {
  UPLOAD_ENTRY_PREPROCESSING_STATUS_TEXT_MAPPER,
  UPLOAD_ENTRY_STATUS_FILTER_PROPS_MAPPER,
  UPLOAD_STATUS_PROPS_MAPPER,
  UPLOAD_STATUS_TEXT_MAPPER,
} from "@constants/static/upload";
import { getFullName } from "@helpers/hooks/objects";
import { getIntOrFloat } from "@helpers/parse/numbers";
import { parseTimeSince } from "@helpers/parse/time";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HealthcareOrganizationTag from "../../HealthcareOrganizationConfigurations/HealthcareOrganizationTag";

export default function ConfigurationsBulkUploadsGridProps({
  model_type,
  bundle_type,
  organization__slug,
  as_staff,
  isStaff,
}) {
  const hiddenColumns = isStaff ? [] : ["submitter_organization"];
  return {
    columns: [
      {
        flex: 1.5,
        field: "submitter_organization",
        headerName: "Healthcare provider",
        renderCell: ({ value }) => (
          <HealthcareOrganizationTag
            submitter_organization={value}
            useLabel={false}
          />
        ),
      },
      {
        flex: 1.5,
        field: "name",
        headerName: "Name",
        renderCell: ({ value }) => (
          <Typography variant="body2" className="ellipsis-2">
            {value}
          </Typography>
        ),
      },
      {
        flex: 1.5,
        field: "entries_summary",
        headerName: "Entries summary",
        renderCell: ({ value }) => {
          const { entry_counts } = value;
          const entries = Object.entries(entry_counts)
            .filter(([_, value]) => value > 0)
            .map(([key, value]) => ({
              value: getIntOrFloat(value),
              name: UPLOAD_ENTRY_PREPROCESSING_STATUS_TEXT_MAPPER[key],
              props: UPLOAD_ENTRY_STATUS_FILTER_PROPS_MAPPER[key],
            }));
          return (
            <CustomGrid>
              <CustomDividedList
                divideAt={3}
                list={entries}
                CustomObjectComponent={({ value, name, props }) => (
                  <CustomTooltip title={name}>
                    <Box {...props}>
                      <Typography variant="body2">{value}</Typography>
                    </Box>
                  </CustomTooltip>
                )}
              />
            </CustomGrid>
          );
        },
      },
      {
        flex: 1,
        field: "status",
        headerName: "Status",
        renderCell: ({ value }) => (
          <CustomStatusTag
            status={value}
            textMapper={UPLOAD_STATUS_TEXT_MAPPER}
            propsMapper={UPLOAD_STATUS_PROPS_MAPPER}
          />
        ),
      },
      {
        flex: 1,
        field: "requesting_user__first_name",
        headerName: "Uploaded by",
        valueGetter: ({ row }) => {
          const { first_name, last_name } = row?.requesting_user || {};
          const full_name = getFullName({ first_name, last_name });
          return full_name || "-";
        },
        renderCell: ({ value }) => (
          <Typography variant="body2" className="ellipsis-2">
            {value}
          </Typography>
        ),
      },
      {
        flex: 1,
        field: "time_updated",
        headerName: "Last updated",
        valueGetter: ({ value }) => parseTimeSince({ value, suffix: "ago" }),
      },
    ].filter(({ field }) => !hiddenColumns.includes(field)),
    disableRowSelectionOnClick: true,
    navigateTo: ({ id }) =>
      getUploadsHref({
        upload__id: id,
        model_type,
        bundle_type,
        organization__slug,
        as_staff,
      }),
  };
}
