import React from "react";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import { getClaimEvaluationResultReportTitle } from "@constants/claims/claims";
import ClaimEvaluationResultMessages from "./ClaimEvaluationResultMessages";
import { getClaimEvaluationResultReportSummary } from "@constants/claims/claim-evaluation-results";

export default function ClaimEvaluationResults({
  results = [],
  isCreateOrUpdateMode = false,
  type,
  useListItemCollapse = true,
  useSectionTitle = false,
  useSecondary = true,
  useCollapse = true,
}) {
  const primary = getClaimEvaluationResultReportTitle({
    useSectionTitle,
    type,
  });

  const secondary = useSecondary
    ? getClaimEvaluationResultReportSummary({ results })
    : "";

  return (
    <CustomListItemCollapse
      primary={primary}
      secondary={secondary}
      useListItemCollapse={useListItemCollapse}
      useCollapse={useCollapse}
    >
      <ClaimEvaluationResultMessages
        type={type}
        isCreateOrUpdateMode={isCreateOrUpdateMode}
        results={results}
      />
    </CustomListItemCollapse>
  );
}
