import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import pluralize from "pluralize";
import CustomLabeledText from "./CustomLabeledText";

export default function CustomAppliedFilters({
  searchQuery = "",
  filters = {},
  describeFilters = () => {},
}) {
  const appliedFilters = describeFilters({ searchQuery, filters }) || [];

  const numFilters = appliedFilters?.length;
  const hasAppliedFilters = numFilters > 0;

  if (!hasAppliedFilters) return null;

  return (
    <Stack spacing={1}>
      <Typography variant="body2">
        {"You have "}
        <b>{numFilters}</b>
        {` ${pluralize("applied filter", numFilters, false)}`}
      </Typography>
      <ul style={{ paddingLeft: 17 }}>
        {appliedFilters.map((props, index) => (
          <AppliedFilter props={props} key={index} />
        ))}
      </ul>
    </Stack>
  );
}

export const CustomQueryParams = ({
  query_params = {},
  describeFilters = () => {},
}) => {
  const { search = "", ...filters } = query_params;
  const appliedFilters =
    describeFilters({ searchQuery: search, filters }) || [];
  const numFilters = appliedFilters?.length;
  const hasAppliedFilters = numFilters > 0;

  return (
    <Stack spacing={1}>
      <CustomLabeledText
        label="Applied filters"
        value="None"
        useChildren={hasAppliedFilters}
      >
        <ul style={{ paddingLeft: 17, margin: 0 }}>
          {appliedFilters.map((props, index) => (
            <AppliedFilter props={props} key={index} />
          ))}
        </ul>
      </CustomLabeledText>
    </Stack>
  );
};

const AppliedFilter = ({ props = {} }) => {
  const { label, value, values = [], unit, from, to } = props || {};
  const output = [
    {
      value: (
        <>
          <b>{label}</b>
          {":"}
        </>
      ),
      predicate: label !== undefined,
    },
    { value: value, predicate: value !== undefined },
    { value: "From", predicate: from !== undefined && to === undefined },
    { value: `${from}${unit || ""}`.trim(), predicate: from !== undefined },
    { value: from !== undefined ? "to" : "Up to", predicate: to !== undefined },
    { value: `${to}${unit || ""}`.trim(), predicate: to !== undefined },
    {
      value: values.map((value, index) => (
        <React.Fragment key={index}>
          {index === 0 ? " " : ", "}
          {value}
        </React.Fragment>
      )),
      predicate: values.length > 0,
    },
  ].filter(({ predicate }) => predicate);

  if (output.length === 0)
    return (
      <Typography
        variant="body2"
        component="li"
        py="4px"
        color="textSecondary"
        fontStyle="italic"
      >
        {"Filter not found."}
      </Typography>
    );

  return (
    <Typography variant="body2" component="li" py="4px">
      {output.map(({ value }, index) => (
        <React.Fragment key={index}> {value} </React.Fragment>
      ))}
    </Typography>
  );
};
