import { AveyBloomApi, getFormData, PayloadError } from "@helpers/api";
import {
  AUTH_GET_SELF,
  AUTH_IS_AUTHENTICATED,
  AUTH_IS_LOADING,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_TOGGLE_CLAIM_MODE,
} from "../../actionTypes/auth";
import {
  ACCESS_TOKEN_KEY,
  getAccessTokens,
  REFRESH_TOKEN_KEY,
  removeAccessTokens,
  storeAccessTokens,
} from "./tokens";

export const login = (data) => async (dispatch) => {
  try {
    const body = JSON.stringify(data);
    const response = await AveyBloomApi().post("/auth/jwt/create/", body);
    storeAccessTokens(response.data);
    dispatch({ type: AUTH_LOGIN });
    return { payload: null };
  } catch (error) {
    dispatch({ type: AUTH_LOGOUT });
    throw new PayloadError({ payload: error?.response?.data?.detail });
  }
};

export async function forgotPassword(data) {
  try {
    const body = JSON.stringify(data);
    await AveyBloomApi().post("/auth/users/reset_password/", body);
    return { payload: null };
  } catch (error) {
    throw new PayloadError({ payload: error?.response?.data });
  }
}

export const verifyAccessToken = () => async (dispatch) => {
  try {
    dispatch({ type: AUTH_IS_LOADING });
    const { [ACCESS_TOKEN_KEY]: access } = getAccessTokens();
    const body = JSON.stringify({ token: access });
    if (!access) throw new PayloadError({ payload: null });
    await AveyBloomApi({ retry: false }).post("/auth/jwt/verify/", body);
    dispatch({ type: AUTH_IS_AUTHENTICATED });
    return { payload: null };
  } catch (error) {
    await refreshToken()
      .then(() => dispatch({ type: AUTH_IS_AUTHENTICATED }))
      .catch(() => dispatch({ type: AUTH_LOGOUT }));
  }
};

export async function refreshToken() {
  try {
    const { [REFRESH_TOKEN_KEY]: refresh } = getAccessTokens();
    if (!refresh) throw new PayloadError({ payload: null });
    const body = JSON.stringify({ refresh });
    const response = await AveyBloomApi({ retry: false }).post(
      "/auth/jwt/refresh/",
      body
    );
    storeAccessTokens(response.data);
    return { payload: null };
  } catch (error) {
    throw new PayloadError({ payload: null });
  }
}

export const getSelf = () => async (dispatch) => {
  try {
    const response = await AveyBloomApi().get("/auth/users/me/");
    dispatch({ type: AUTH_GET_SELF, payload: response.data });
    return { payload: null };
  } catch (error) {
    dispatch({ type: AUTH_LOGOUT });
    throw new PayloadError({ payload: error?.response?.data?.detail });
  }
};

export const logout = () => (dispatch) => {
  removeAccessTokens();
  dispatch({ type: AUTH_LOGOUT });
};

export const updateUser =
  (data, isSelf = true) =>
  async (dispatch) => {
    try {
      const formData = getFormData(data);
      const response = await AveyBloomApi({
        headers: { "Content-Type": "multipart/form-data" },
      }).put(`/users/users/${data.id}/`, formData);
      isSelf && dispatch({ type: AUTH_GET_SELF, payload: response.data });
      return { payload: response.data };
    } catch (error) {
      throw new PayloadError({ payload: error?.response?.data?.reason || {} });
    }
  };

export const updateUserPhoneNumber = (data) => async (dispatch) => {
  try {
    const response = await AveyBloomApi().put(
      "/users/users/update_phone_number/",
      data
    );
    dispatch({
      type: AUTH_GET_SELF,
      payload: response.data,
    });
    return { payload: response.data };
  } catch (error) {
    throw new PayloadError({ payload: error?.response?.data?.reason || {} });
  }
};

export async function updatePassword(data) {
  try {
    await AveyBloomApi().post("/auth/users/set_password/", data);
    return { payload: null };
  } catch (error) {
    throw new PayloadError({ payload: error?.response?.data });
  }
}

export async function resetPassword(data) {
  try {
    const body = JSON.stringify(data);
    await AveyBloomApi().post("/auth/users/reset_password_confirm/", body);
    return { payload: null };
  } catch (error) {
    throw new PayloadError({
      payload: error?.response?.data?.new_password?.join(" "),
    });
  }
}

export async function verifyUidToken(data) {
  try {
    const response = await AveyBloomApi().post(
      "/users/verify_uid_token/",
      data
    );
    return { payload: response.data?.email };
  } catch (error) {
    throw new PayloadError({ payload: error?.response?.data?.reason });
  }
}

export async function activateUser(data) {
  try {
    const body = JSON.stringify(data);
    await AveyBloomApi().post("/users/djoser_users/activation/", body);
    return { payload: null };
  } catch (error) {
    throw new PayloadError({
      payload:
        error?.response?.data?.new_password?.join(" ") ||
        error?.response?.data?.token,
    });
  }
}

export async function submitFeedback(data) {
  try {
    await AveyBloomApi().post("/users/user_feedbacks/", data);
    return { payload: null };
  } catch (error) {
    throw new PayloadError({ payload: error?.response?.data?.reason });
  }
}

export const toggleClaimMode =
  (payload = undefined) =>
  (dispatch) =>
    dispatch({ type: AUTH_TOGGLE_CLAIM_MODE, payload });
