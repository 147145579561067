import React from "react";
import CustomSection from "@components/Custom/CustomSection";
import {
  getClaimMedicationSectionId,
  getClaimProcedureSectionId,
} from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_SECTION_DIAGNOSES,
  CLAIM_SECTION_MEDICATIONS,
  CLAIM_SECTION_ORGANIZATION_CLINICIAN,
  CLAIM_SECTION_ORGANIZATION_PATIENT,
  CLAIM_SECTION_PROCEDURES,
  CLAIM_SECTION_RESULTS_MAPPER,
  CLAIM_SECTION_SYMPTOMS,
  CLAIM_SECTION_WIDE,
} from "@constants/claims/claims";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ClaimEvaluationResults from "..";
import { useClaimContext } from "../../context";
import ClaimEvaluationResultLink from "../ClaimEvaluationResultLink";

const suffixReport = (prefix) => `${prefix} report`;

export default function ClaimWideEvaluationResultsSummary() {
  const { claim, isCreateOrUpdateMode } = useClaimContext();
  const {
    [CLAIM_SECTION_RESULTS_MAPPER[CLAIM_SECTION_WIDE]]:
      claim_evaluation_results,
    [CLAIM_SECTION_RESULTS_MAPPER[CLAIM_SECTION_ORGANIZATION_PATIENT]]:
      claim_patient_details_evaluation_results,
    [CLAIM_SECTION_RESULTS_MAPPER[CLAIM_SECTION_ORGANIZATION_CLINICIAN]]:
      claim_clinician_details_evaluation_results,
    [CLAIM_SECTION_RESULTS_MAPPER[CLAIM_SECTION_SYMPTOMS]]:
      claim_symptoms_evaluation_results,
    [CLAIM_SECTION_RESULTS_MAPPER[CLAIM_SECTION_DIAGNOSES]]:
      claim_diagnoses_evaluation_results,
    claim_procedures = [],
    claim_medications = [],
  } = claim;

  const claim_section_results = [
    {
      results: claim_evaluation_results,
      type: CLAIM_SECTION_WIDE,
    },
    {
      results: claim_patient_details_evaluation_results,
      type: CLAIM_SECTION_ORGANIZATION_PATIENT,
    },
    {
      results: claim_clinician_details_evaluation_results,
      type: CLAIM_SECTION_ORGANIZATION_CLINICIAN,
    },
    {
      results: claim_symptoms_evaluation_results,
      type: CLAIM_SECTION_SYMPTOMS,
    },
    {
      results: claim_diagnoses_evaluation_results,
      type: CLAIM_SECTION_DIAGNOSES,
    },
  ].filter(({ results }) => results.length > 0);

  const subclaim_section_results = [
    {
      subclaims: claim_procedures,
      type: CLAIM_SECTION_PROCEDURES,
      results_key: CLAIM_SECTION_RESULTS_MAPPER[CLAIM_SECTION_PROCEDURES],
      blankName: "Procedure",
      getSubclaimSectionId: getClaimProcedureSectionId,
    },
    {
      subclaims: claim_medications,
      type: CLAIM_SECTION_MEDICATIONS,
      results_key: CLAIM_SECTION_RESULTS_MAPPER[CLAIM_SECTION_MEDICATIONS],
      blankName: "Medication",
      getSubclaimSectionId: getClaimMedicationSectionId,
    },
  ].filter(
    ({ subclaims, results_key }) =>
      subclaims.length > 0 &&
      subclaims?.some(({ [results_key]: results }) => results?.length > 0)
  );

  const hasClaimSectionResults = claim_section_results.length > 0;
  const hasSubclaimSectionResults = subclaim_section_results.length > 0;

  if (!hasClaimSectionResults && !hasSubclaimSectionResults) return null;

  return (
    <CustomSection title="Report Summary">
      <Stack spacing={3}>
        {claim_section_results.map(({ results, type }, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <Divider />}
            <ClaimEvaluationResults
              results={results}
              isCreateOrUpdateMode={false}
              type={type}
              useListItemCollapse={true}
              useSectionTitle={true}
              useSecondary={false}
              useCollapse={false}
            />
          </React.Fragment>
        ))}
        {subclaim_section_results.map((props, index) => (
          <SubclaimsEvaluationResultsSummary
            key={index}
            index={index}
            isCreateOrUpdateMode={isCreateOrUpdateMode}
            hasClaimSectionResults={hasClaimSectionResults}
            {...props}
          />
        ))}
      </Stack>
    </CustomSection>
  );
}

const SubclaimsEvaluationResultsSummary = ({
  index = 0,
  isCreateOrUpdateMode = false,
  hasClaimSectionResults = false,
  subclaims = [],
  type,
  results_key,
  blankName,
  getSubclaimSectionId = () => {},
}) => {
  return subclaims
    .filter(
      ({ [results_key]: subclaim_evaluation_results }) =>
        (subclaim_evaluation_results || [])?.length > 0
    )
    .map(
      ({
        id,
        inputted_internal_code,
        organization_bundle,
        [results_key]: subclaim_evaluation_results,
      }) => (
        <React.Fragment key={id}>
          {(hasClaimSectionResults || index !== 0) && <Divider />}
          <Stack spacing="12px">
            <ClaimEvaluationResultLink
              to={getSubclaimSectionId({
                isCreateOrUpdateMode,
                id,
              })}
              value={suffixReport(
                organization_bundle?.internal_code ||
                  inputted_internal_code ||
                  blankName
              )}
              isUnderlined={false}
              fontWeight="bold"
              title={organization_bundle?.description}
            />
            <ClaimEvaluationResults
              results={subclaim_evaluation_results}
              isCreateOrUpdateMode={false}
              type={type}
              useListItemCollapse={false}
              useSecondary={false}
            />
          </Stack>
        </React.Fragment>
      )
    );
};
