import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  getOrganizationPatientsHref,
  getOrganizationSetupDefaultHref,
  INSURANCE_CONFIGURATIONS_BASE_HREFS,
  ORGANIZATION_PATIENTS_TITLE,
} from "@constants/static/routing";
import { getFullName } from "@helpers/hooks/objects";
import { useQueryFetchOrganization } from "@redux/actions/organizations/organizations";
import { useParams } from "react-router-dom";
import OrganizationPatientActivateForm from "../OrganizationPatientForms/OrganizationPatientActivateForm";
import OrganizationPatientUpdateForm from "../OrganizationPatientForms/OrganizationPatientUpdateForm";
import OrganizationPatientVersionHistory from "./OrganizationPatientVersionHistory";

export function OrganizationPatientHeaderTitle({ object, variant = "h3" }) {
  const { version, first_name, last_name, patient_id, time_deleted } = object;
  const full_name = getFullName({ first_name, last_name });

  return (
    <CustomHeaderTitle
      title={full_name || patient_id}
      subtitle={full_name ? patient_id : full_name}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function OrganizationPatientHeader({ object }) {
  const { organization__slug, organization_patient__id } = useParams();
  const { data: organization } = useQueryFetchOrganization({
    organization__slug,
  });
  const { patient_id, time_deleted } = object || {};
  const isDeleted = Boolean(time_deleted);
  const title = patient_id || organization_patient__id;

  const hrefs = [
    ...INSURANCE_CONFIGURATIONS_BASE_HREFS,
    {
      label: organization?.name || organization__slug,
      href: getOrganizationSetupDefaultHref({ organization__slug }),
    },
    {
      label: ORGANIZATION_PATIENTS_TITLE,
      href: getOrganizationPatientsHref({ organization__slug }),
    },
    {
      label: title,
      href: getOrganizationPatientsHref({
        organization__slug,
        organization_patient__id,
      }),
    },
  ];
  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={OrganizationPatientHeaderTitle}
      titleComponentProps={{ object }}
    >
      {!isDeleted && (
        <OrganizationPatientUpdateForm
          object={object}
          isSquare={false}
          withNavigate={true}
          isFullScreen={true}
        />
      )}
      <CustomMoreActions>
        <OrganizationPatientVersionHistory />
        <OrganizationPatientActivateForm object={object} />
      </CustomMoreActions>
    </CustomHeader>
  );
}
