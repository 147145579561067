import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomButton from "@components/Custom/CustomButton";
import CustomButtonVariant from "@components/Custom/CustomButtonVariant";
import CustomFileDropzone from "@components/Custom/CustomFileDropzone";
import { CustomExternalLink } from "@components/Custom/CustomLink";
import useFetchOrganization from "@helpers/hooks/useFetchObjects/useFetchOrganization";
import useFetchOrganizationAgreement from "@helpers/hooks/useFetchObjects/useFetchOrganizationAgreement";
import {
  createClaimSubmissionUpload,
  updateClaimSubmissionUploadObjectTSQ,
} from "@redux/actions/claims/claim-submission-uploads";
import { updateClaimSubmissionObjectTSQ } from "@redux/actions/claims/claim-submissions";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { parseClaimSubmissionUploadSnackbar } from "../ClaimSubmissionUploadSnackbar";

const emptyFormData = { file: null };
const label = "Upload claims";
const icon = "PublishIcon";

export default function ClaimSubmissionUploadCreateForm({
  isMenuItem = false,
  buttonProps = {},
}) {
  const { claim_submission__id } = useParams();
  const { data: processor_organization } = useFetchOrganization();
  const { isAgreementDisabled, isLoading } = useFetchOrganizationAgreement();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(emptyFormData);
  const { is_bulk_upload_enabled = false, mds_template_file } =
    processor_organization || {};
  const { file } = formData;
  const hasFile = Boolean(file);

  const handleChangeFile = (newValue) =>
    setFormData({ ...formData, file: newValue });

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (Boolean(formData?.file)) {
      setIsProcessing(true);
      createClaimSubmissionUpload({
        claim_submission: claim_submission__id,
        ...formData,
      })
        .then(({ payload }) => {
          setIsProcessing(false);
          setIsOpen(false);
          const { instance } = payload;
          enqueueSnackbar(parseClaimSubmissionUploadSnackbar(instance));
          updateClaimSubmissionUploadObjectTSQ({ object: instance });
          updateClaimSubmissionObjectTSQ({
            object: instance?.claim_submission,
          });
        })
        .catch(({ payload }) => {
          setIsProcessing(false);
          enqueueSnackbar({ message: payload, variant: "error" });
        });
    } else {
      enqueueSnackbar({
        variant: "error",
        message: "Upload a valid .csv file to proceed",
      });
    }
  };

  React.useEffect(() => {
    isOpen && setFormData(emptyFormData);
  }, [isOpen]);

  if (!is_bulk_upload_enabled || isAgreementDisabled || isLoading)
    return (
      <CustomButtonVariant
        label={label}
        isMenuItem={isMenuItem}
        disabled={true}
        buttonProps={{ icon, variant: "outlined", fullWidth: true }}
      />
    );

  return (
    <>
      <CustomButtonVariant
        label={label}
        onClick={handleIsOpen}
        isMenuItem={isMenuItem}
        buttonProps={{
          icon,
          variant: "outlined",
          fullWidth: true,
          ...buttonProps,
        }}
      />
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Upload claims" }}
        yesText="Upload"
      >
        <CustomFileDropzone
          value={file}
          onChange={handleChangeFile}
          accept={{
            "text/csv": [".csv", ".tsv"],
            "text/tab-separated-values": [".tsv"],
            "application/vnd.ms-excel": [".xls", ".xlsx"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [".xlsx"],
          }}
          fileType=".xlsx" // Default
        />
        {!hasFile && Boolean(mds_template_file) && (
          <CustomExternalLink
            isTypography={false}
            href={mds_template_file}
            wrapperProps={{ download: "template" }}
          >
            <CustomButton
              variant="outlined"
              label="Download template"
              fullWidth={true}
            />
          </CustomExternalLink>
        )}
      </CustomActionDialog>
    </>
  );
}
