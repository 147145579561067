import React from "react";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";

export function CustomExternalLink({
  href = "",
  label = "",
  wrapperProps = {},
  children = null,
  isTypography = true,
}) {
  const { download = false } = wrapperProps || {};
  const onClick = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (Boolean(download)) {
      fetch(href)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          if (typeof download === "string") link.download = download;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => console.error("Error downloading the file:", err));
    }
  };

  if (isTypography)
    return (
      <Typography
        href={href}
        target="_blank"
        rel="noreferrer"
        fontWeight="bold"
        variant="body2"
        className="opacityOnHover-50 underlined button"
        onClick={onClick}
        {...wrapperProps}
      >
        {label}
      </Typography>
    );

  return (
    <div className="opacityOnHover-50" onClick={onClick} {...wrapperProps}>
      {children}
    </div>
  );
}

export function CustomTypographyLink({ href = "", children, ...props }) {
  return (
    <Typography
      className="underlined"
      variant="body2"
      fontWeight="bold"
      {...props}
    >
      <NavLink to={href} className="opacityOnHover-50">
        {children}
      </NavLink>
    </Typography>
  );
}
