import {
  CLAIM_SUBMISSION_UPLOAD_STATUS_CANCELED,
  CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE,
  CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
  CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS,
} from "@constants/claims/claim-submission-uploads";

export const getClaimSubmissionUploadSnackbarKey = ({
  id,
  preprocessing_status,
}) => `claim-submission-upload-${id}-${preprocessing_status}`;

export const parseClaimSubmissionUploadSnackbar = (object) => {
  const { id, preprocessing_status } = object;
  const key = getClaimSubmissionUploadSnackbarKey({ id, preprocessing_status });
  const context = getSnackbarContext(object);
  const variant = "CustomSnackbar";
  const anchorOrigin = { vertical: "bottom", horizontal: "right" };

  return { key, variant, context, anchorOrigin, persist: true };
};

const getSnackbarContext = (object) => {
  const { id, preprocessing_status, claim_submission, file } = object;
  const { name, processor_organization } = claim_submission || {};
  const image = processor_organization?.image;
  const key = getClaimSubmissionUploadSnackbarKey({ id, preprocessing_status });
  const context = { key, image };
  const hasFile = Boolean(file);

  switch (preprocessing_status) {
    case CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING:
      return {
        ...context,
        isLoadingVariant: true,
        title: (
          <>
            {hasFile ? "Uploading " : "Processing "}
            <b>{name}</b>
          </>
        ),
      };

    case CLAIM_SUBMISSION_UPLOAD_STATUS_FAILURE:
      return {
        ...context,
        isErrorVariant: true,
        title: (
          <>
            <b>{name}</b>
            {` could not be ${hasFile ? "uploaded" : "processed"}`}
          </>
        ),
      };

    case CLAIM_SUBMISSION_UPLOAD_STATUS_SUCCESS:
      return {
        ...context,
        isSuccessVariant: true,
        title: (
          <>
            <b>{name}</b>
            {` successfully ${hasFile ? "uploaded" : "processed"}`}
          </>
        ),
      };

    case CLAIM_SUBMISSION_UPLOAD_STATUS_CANCELED:
      return {
        ...context,
        isSuccessVariant: true,
        title: (
          <>
            <b>{name}</b>
            {` ${hasFile ? "upload" : "task"} canceled`}
          </>
        ),
      };

    default:
      return context;
  }
};
