import React from "react";
import CustomHeader from "@components/Custom/CustomLayout/CustomHeader";
import CustomHeaderTitle from "@components/Custom/CustomLayout/CustomHeaderTitle";
import CustomMoreActions from "@components/Custom/CustomMoreActions";
import {
  HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
  ORGANIZATION_CLINICIANS_TITLE,
  getOrganizationCliniciansHref,
} from "@constants/static/routing";
import { getFullName } from "@helpers/hooks/objects";
import { selectorAuthHasAdminPrivileges } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import OrganizationClinicianActivateForm from "../OrganizationClinicianForms/OrganizationClinicianActivateForm";
import OrganizationClinicianUpdateForm from "../OrganizationClinicianForms/OrganizationClinicianUpdateForm";
import OrganizationClinicianVersionHistory from "./OrganizationClinicianVersionHistory";

export function OrganizationClinicianHeaderTitle({ object, variant = "h3" }) {
  const { version, first_name, last_name, time_deleted } = object;
  const full_name = getFullName({ first_name, last_name });

  return (
    <CustomHeaderTitle
      title={full_name}
      variant={variant}
      version={version}
      time_deleted={time_deleted}
    />
  );
}

export default function OrganizationClinicianHeader({ object }) {
  const { organization_clinician__id } = useParams();
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const { clinician_id, time_deleted } = object;
  const title = clinician_id || organization_clinician__id;
  const isDeleted = Boolean(time_deleted);

  const hrefs = [
    ...HEALTHCARE_CONFIGURATIONS_BASE_HREFS,
    {
      label: ORGANIZATION_CLINICIANS_TITLE,
      href: getOrganizationCliniciansHref({}),
    },
    {
      label: title,
      href: getOrganizationCliniciansHref({
        organization_clinician__id,
      }),
    },
  ];

  return (
    <CustomHeader
      hrefs={hrefs}
      TitleComponent={OrganizationClinicianHeaderTitle}
      titleComponentProps={{ object }}
    >
      {hasAdminPrivileges && !isDeleted && (
        <OrganizationClinicianUpdateForm
          object={object}
          isSquare={false}
          withNavigate={true}
        />
      )}
      <CustomMoreActions>
        <OrganizationClinicianVersionHistory />
        {hasAdminPrivileges && (
          <OrganizationClinicianActivateForm object={object} />
        )}
      </CustomMoreActions>
    </CustomHeader>
  );
}
