import React from "react";
import { useQueryfetchNdcTags } from "@redux/actions/avey/ndc_tags";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getNdcTagOptionLabel,
  isNdcTagOptionEqualToValue,
  renderNdcTagOption,
} from "./NdcTagAutocompleteComponents";

export default function NdcTagAutocomplete({
  label = "NDC Tag",
  error = false,
  value,
  handleChange = () => {},
  wrapperProps = {},
  textFieldProps = {},
  autocompleteProps = {},
}) {
  const useQueryFnProps = {
    useInfiniteWrapper: true,
    sortQuery: "code",
  };

  return (
    <CustomInfiniteAutocomplete
      label={label}
      error={error}
      wrapperProps={wrapperProps}
      textFieldProps={{ required: true, ...textFieldProps }}
      autocompleteProps={{
        disableClearable: true,
        renderOption: renderNdcTagOption,
        ...autocompleteProps,
      }}
      useQueryFn={useQueryfetchNdcTags}
      useQueryFnProps={useQueryFnProps}
      getOptionLabel={getNdcTagOptionLabel}
      isOptionEqualToValue={isNdcTagOptionEqualToValue}
      value={value}
      handleChange={handleChange}
    />
  );
}
