import React from "react";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import { getFullName } from "@helpers/hooks/objects";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AccountInformationUpdateForm from "./AccountInformationUpdateForm";

export default function AccountInformation({ user }) {
  const { first_name, last_name, email } = user;
  const full_name = getFullName({ first_name, last_name });

  return (
    <Stack spacing={2}>
      <div>
        <Stack
          spacing={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight="bold">{"Your information"}</Typography>
          <AccountInformationUpdateForm user={user} />
        </Stack>
      </div>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CustomLabeledText label="Name" value={full_name} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomLabeledText label="Email" value={email} />
          </Grid>
        </Grid>
      </div>
    </Stack>
  );
}
