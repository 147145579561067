import ClaimIcdEvaluationResultContext from "./ClaimIcdEvaluationResultContext";

export default function ClaimSymptomEvaluationResultContext({
  result = {},
  isCreateOrUpdateMode = false,
}) {
  const {
    claim_symptom,
    claim_procedures,
    icd_code,
    icd_codes,
    description,
    error_message,
  } = result || {};
  const { icd } = claim_symptom || {};

  return ClaimIcdEvaluationResultContext({
    icd,
    claim_procedures,
    icd_code,
    icd_codes,
    description,
    error_message,
    isCreateOrUpdateMode,
  });
}
