import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CloseIcon, PlusIcon, SearchIcon } from "@rimads/assets";
import pluralize from "pluralize";
import CustomContextSearchField from "../CustomContextSearchField";
import { CustomOutlinedField } from "../CustomOutlinedField";
import CustomTypographyButton from "../CustomTypographyButton";

const customNoOptionsText = ({ searchQuery, noOptionsTextProps = {} }) => {
  const { label = "option", handleIsOpen } = noOptionsTextProps || {};
  const hasHandleIsOpen = typeof handleIsOpen === "function";

  if (hasHandleIsOpen) {
    return (
      <div>
        <Typography variant="body2" display="inline" mr>
          {"Not in the list?"}
        </Typography>
        {hasHandleIsOpen && (
          <CustomTypographyButton
            display="inline"
            value={`+ Create a new ${label?.toLowerCase()}`}
            color="textPrimary"
            onClick={(e) => {
              e.stopPropagation();
              handleIsOpen({ searchQuery });
            }}
          />
        )}
      </div>
    );
  }

  return (
    <Typography variant="body2">{`No ${pluralize(label, 0)} found`}</Typography>
  );
};

export default function CustomAutocomplete({
  placeholder = "Search...",
  searchQuery = "",
  value = null,
  handleChange = () => {},
  getOptionLabel = (option) => option?.name,
  isOptionEqualToValue = () => false,
  textFieldProps = {},
  withSearchIcon = true,
  loading = false,
  options = [],
  setSearchQuery = () => {},
  label = "",
  error = false,
  helperText = "",
  wrapperProps = {},
  ...otherProps
}) {
  const { noOptionsTextProps, ...otherAutocompleteProps } = otherProps;
  const { handleIsOpen } = noOptionsTextProps || {};

  const hasHandleIsOpen = typeof handleIsOpen === "function";

  const handleClear = () => setSearchQuery("");
  return (
    <CustomOutlinedField
      label={label}
      error={error}
      helperText={helperText}
      wrapperProps={wrapperProps}
    >
      <Stack spacing={1} direction="row" alignItems="center">
        <div style={{ flexGrow: 1 }}>
          <Autocomplete
            options={options || []}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            onFocus={handleClear}
            renderInput={(params) => {
              const {
                InputProps: paramInputProps = {},
                InputLabelProps: paramsInputLabelProps = {},
                ...otherParams
              } = params;
              const {
                InputProps: textFieldInputProps = {},
                InputLabelProps: textFieldInputLabelProps = {},
                ...otherTextFieldProps
              } = textFieldProps;
              return (
                <CustomContextSearchField
                  placeholder={placeholder}
                  setSearchQuery={setSearchQuery}
                  useDefaultTextField={true}
                  variant="standard"
                  fullWidth
                  InputProps={{
                    ...paramInputProps,
                    disableUnderline: true,
                    startAdornment: withSearchIcon ? (
                      <InputAdornment position="start">
                        <SearchIcon dimension={18} />
                      </InputAdornment>
                    ) : null,
                    ...textFieldInputProps,
                  }}
                  InputLabelProps={{
                    ...paramsInputLabelProps,
                    ...textFieldInputLabelProps,
                  }}
                  debounceDuration={400}
                  {...otherTextFieldProps}
                  {...otherParams}
                />
              );
            }}
            loading={loading}
            onChange={(e, newValue, reason) => {
              reason === "clear" && handleClear();
              handleChange(newValue);
            }}
            filterOptions={(options) => options}
            value={value}
            slotProps={{ paper: { className: "elevated-8" } }}
            noOptionsText={customNoOptionsText({
              searchQuery,
              noOptionsTextProps,
            })}
            clearIcon={<CloseIcon />}
            popupIcon={null}
            {...otherAutocompleteProps}
          />
        </div>
        {hasHandleIsOpen && (
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleIsOpen({ searchQuery });
            }}
          >
            <PlusIcon dimension={10} />
          </IconButton>
        )}
      </Stack>
    </CustomOutlinedField>
  );
}
