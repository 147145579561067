import React from "react";
import Custom404 from "@components/Custom/Custom404";
import CustomBackdrop from "@components/Custom/CustomBackdrop";
import CustomPage from "@components/Custom/CustomLayout/CustomPage";
import { getClaimHref } from "@constants/static/routing";
import useFetchClaim from "@helpers/hooks/useFetchObjects/useFetchClaim";
import { Navigate } from "react-router";
import ClaimBody from "./ClaimBody";
import ClaimHeader from "./ClaimHeader";
import { ClaimContext } from "./context";

export default function Claim() {
  const {
    data,
    hasData,
    isLoading,
    organization__slug,
    claim_submission__id,
    claim__id,
    urlSearch,
  } = useFetchClaim();

  if (isLoading) return <CustomBackdrop />;

  if (!hasData) return <Custom404 />;

  const { latest_version_id = null } = data || {};
  if (Boolean(latest_version_id) && latest_version_id !== claim__id) {
    const latestVersionHref = getClaimHref({
      organization__slug,
      claim_submission__id,
      claim__id: latest_version_id,
      urlSearch,
    });
    return <Navigate to={latestVersionHref} />;
  }

  return (
    <ClaimContext.Provider value={{ claim: data }}>
      <ClaimHeader />
      <CustomPage>
        <ClaimBody useSummary={true} />
      </CustomPage>
    </ClaimContext.Provider>
  );
}
